.carousel {
  perspective: 250px;
  display: flex;
  justify-content: center;
}
.carousel .carousel-item {
  width: 450px;
  /* width: 100%; */
  background: transparent;
  padding: 10px;
  height: auto;
  text-align: center;
  border-radius: 15px;
}

.testi p {
  font-size: 18px;
  line-height: 1.9;
  margin-bottom: 10px;
  flex-basis: 100%;
}


@media screen and (max-width: 435px) {
  .carousel .carousel-item {
    width: 320px;
    margin-bottom: 20px;
  }

  .testi p {
    font-size: 15px;
  }
}