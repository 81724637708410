.grid-container {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0px;
  padding: 50px;
  grid-template-rows: 275px 275px;
}

.image-box {
  background-size: cover;
}

.grid-box {
  display: grid;
  background-color: orange;
  grid-template-columns: auto 1fr;
  gap: 20px;
  align-items: center;
  padding: 20px;
}

.paragraph-box p,
h1 {
  color: #fff;
  margin: 0;
  margin-top: 10px;
}

.vertical-ruler {
  border-left: 2px solid white;
  height: 100%;
  margin-left: 10px;
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start; /* Align content at the top */
}

#content1 {
  background-color: #424b5a;
}

#content2 {
  background-color: #ff7420;
}

#content3 {
  background-color: #191a19;
}

#content4 {
  background-color: #54514e;
}

@media screen and (max-width: 1200px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
    padding: 30px;
  }
}

@media screen and (max-width: 700px) {
  .grid-container {
    grid-template-columns: repeat(1, 1fr); /* One column */
    grid-template-rows: repeat(9, 1fr);
    padding: 20px;
    /* grid-auto-flow: column; */

    .image-box{
      background-position: center; 
    }
  }
}

@media screen and (max-width: 1345px) {
  .grid-container {
    padding: 5px;
  }
}

