.bs-container {
  /*background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%),
    url('../../../assets/images/image2001.png');*/
  background-color: rgba(0, 0, 0, 0.9);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* height: 500px; */
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: row; */
  justify-content: center;
  /* align-items: flex-start; */
  /* color: ${mainTheme.colors.fontPrimary}; */
  text-align: left;
  padding: 20px;
  /* font-family: ${mainTheme.typography.mainFont}; */

}

.bs-container h1 {
  font-size: 40px;
  /* font-weight: ${mainTheme.fontWeight.semiBold}; */
  margin-bottom: 0;
}

.main-div1 {
  flex: 0.9;
}

.main-div2 {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}

.bs-container p {
  /* color: ${mainTheme.colors.fontSecondary};
    font-weight: ${mainTheme.fontWeight.regular}; */
  font-size: 20px;
  margin-bottom: 40px;
}

.timer-grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.timer-box {
  background-image: linear-gradient(to bottom, rgba(55, 56, 53, 0.8) 0%, rgba(55, 56, 53, 0.8) 100%);
  width: 138px;
  height: 127px;
  margin: 10px;
  padding: 30px 0;
  border-radius: 9px;
  text-align: center;
}

.timer-box img {
  height: 39px;
}

.timer-text {
  /* color: ${mainTheme.colors.primary}; */
  font-size: 15px;
  /* font-weight: ${mainTheme.fontWeight.semiBold}; */
}

/* Media queries */
@media screen and (max-width: 1200px) {
  .bs-container {
    flex-direction: column;
    align-items: center;
  }

  .bs-container h1 {
    font-size: 30px;
    text-align: center;
  }

  .bs-container p {
    text-align: center;
  }

  .timer-grid {
    justify-content: center;
  }

  .main-div1 {
    flex-basis: 100%;
  }

  .main-div2 {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 1200px) {
  .bs-container{
    padding: 0;
  }
}