/* CompanyTrust.css */

.trust-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 30px 10px;
  overflow: hidden;
}

/* .experience-section-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
} */

.experience-images-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  margin-bottom: 20px;
}

.experience-image img {
  height: auto;
  width: 100%;
  max-width: 233px;
}

.experience-content {
  text-align: center;
  width: 100%;
}

.experience-text {
  margin: 10px 0;
}

.btn-container {
  margin-top: 20px;
}

.experience-image-1 {
  align-items: end;
}

.experience-image-2 {
  align-items: start;
}

.trust-grid {
  padding: 20px;
}

.content {
  flex: 1;
  padding: 0px 20px;
}

.image-column {
  height: 100%;
  margin: 0px 5px;
  /* align-items: center; */
}

/* .image {
  width: 100%;
  height: auto;
} */

.content {
  display: block;
}

.title {
  font-size: 30px;
}

.title span {
  color: #ff7420;
}

.paragraph {
  font-family: 'poppins';
  font-size: 18px;
  margin-bottom: 10px;
  text-align: justify;
  word-spacing: 1px;
}

.button {
  margin-left: 20px;
  cursor: pointer;
  width: 210px;
  height: 50px;
}

.btn-container {
  display: flex;
  justify-content: start;
  width: 100%;
}

/* Media queries */
@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .trust-grid {
    flex-basis: 100%;
  }

  .image-grid {
    width: 90vw;
  }

  .title {
    width: 100%;
    font-size: 24px;
    text-align: center;
  }

  .paragraph {
    text-align: center;
  }

  .button {
    margin-left: 0;
    margin-top: 20px;
    width: 100%;
  }
  .btn-container {
    justify-content: center;
  }
}

@media screen and (max-width: 1100px) {
  .trust-grid {
    flex: 0 0 100%;
  }

  .content {
    flex: 0 0 100%;
    padding: 20px;
  }

  .title {
    font-size: 24px;
    text-align: center;
  }

  .btn-container {
    justify-content: center;
  }
}
