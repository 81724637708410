.input:focus {
  outline: none;
}

input,
select,
textarea {
  border-style: none;
  border-color: Transparent;
}

textarea:focus,
input:focus {
  outline: none;
  border-bottom: none !important;
}

.section {
  padding: 40px 20px;
  text-align: center;
  background-color: rgba(255, 116, 32, 0.05);
}

.header-tw {
  color: black;
  text-align: center;
  font-size: 35px;
  font-weight: bold;
  /* margin: 40px 0; */
}

.container {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.innerHeader {
  font-size: 25px;
  font-weight: bold;
  margin-top: 10px;
}

.column {
  flex: 1;
  max-width: 400px;
  margin: 40px 20px;
}

.iconRow {
  padding: 0px 10px 30px 0px;
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 15px;
  height: 25px;
  vertical-align: middle; /* Adjust vertical alignment */
}

.iconText {
  font-size: 16px;
  line-height: 1; /* Set line-height to 1 to ensure text height matches icon height */
}

.innerParah {
  padding: 0px 30px 30px 0px;
  font-weight: lighter;
  font-size: 14px;
}

.leftColumn {
  text-align: left;
}

.submitButton {
  padding: 10px;
  width: 100%;
  background: #007bff;
  color: #fff;
  border: none;
}

.inputFieldWrapper {
  width: 100%;
  margin-bottom: 10px;
  background-color: transparent;
  border-radius: 15px;
  background: transparent;
  color: white;
  max-height: 500px;
  text-decoration: none !important;
  padding: 8px;
}

.inputField {
  border-radius: 15px;
  width: 100%;
  height: 100%;
  padding: 5px;
  border: 0 !important;
  outline: none !important;
  background-color: transparent;
}

.textareaField {
  /* background-color: #fff; */
  border-radius: 15px;
  padding: 10px 0 0 10px;
  height: 150px;
  border-style: none;
  border-color: Transparent;
  overflow: auto;
  outline: none;
}

.btn-wrapper {
  display: flex;
  justify-content: center;
}

/* Media queries for responsiveness */
@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center; /* Center items vertically */
  }

  .column {
    width: 100%;
    max-width: 100%; /* Occupy full width on smaller screens */
    margin: 20px 0; /* Add margin between columns */
  }

  /*.iconRow {
    justify-content: center;
  }*/

  .innerHeader,
  .innerParah {
    text-align: center;
  }

  .icon {
    margin-right: 5px;
  }

  .inputFieldWrapper {
    max-width: 300px;
    margin: 0 auto 20px auto;
  }

  .submitButton {
    width: 100%;
  }
}
