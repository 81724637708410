.strip-container {
  color: #fff; /* Adjust text color as needed */
  padding: 20px 20px; /* Adjust padding as needed */
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Box shadow for elevation */
}

.strip-text {
  margin: 0;
  color: black;
  font-size: 32px;
  font-weight: bold;
}
