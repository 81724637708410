.button-section {
  display: flex;
  flex-wrap: wrap;
}

.single-button-section {
  padding: 15px;
}

@media screen and (max-width: 667px) {
  .button-section {
    padding-left: 40px;
  }
}
