.quote-section-container {
  background-color: #ffffff;
  margin: 0 300px;
  border-radius: 15px;
}

@media (max-width: 820px) {
  .quote-section-container {
    margin: 0 100px;
  }
}

@media (max-width: 1000px) {
  .quote-section-container {
    margin: 0 50px;
  }
}

