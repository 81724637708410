.inputMain {
  flex: 1;
  width: 100%;
  /* margin: 40px 20px; */
}

.buttonRow {
  display: flex;
  align-items: end;
  justify-content: end;
}

.gallery-logo {
  margin-right: 10px;
}

.logoContainer {
  display: flex;
  justify-content: center;
}

.navBarStart,
.navBarEnd {
  display: flex;
  gap: 10px;
  align-items: center;
}

.item-left {
  margin-right: auto; /* Pushes this item to the left */
}

.item-right {
  margin-left: auto; /* Pushes this item to the right */
}

.navBarMain {
  display: flex;
}

.flex-container {
  display: flex;
  flex-grow: 1;
  width: fit-content;
  flex-direction: column;
  justify-content: space-between; /* Items are spaced evenly along the main axis */
}

.top-items {
  align-self: flex-start; /* Items align to the start of the cross axis */
}
