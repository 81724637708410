.experience-section-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 30px 10px;
  overflow: hidden;
}
.experience-image {
  height: 100%;
  margin: 0px 5px;
  align-items: center;
}

.experience-image-1 {
  align-items: end;
}

.experience-image-2 {
  align-items: start;
}

.experience-title {
  margin-top: 10px;
}

.experience-subtitle {
  margin: 0;
}

.experience-images-container {
  padding: 20px;
  /* flex: 1; */
}

.experience-content {
  padding: 0 20px;
  flex: 1;
}

.experience-title {
  font-size: 48px;
}

.experience-subtitle {
  font-size: 48px;
}

.experience-text {
  font-family: 'poppins';
  font-size: 18px;
  margin-bottom: 20px;
}

/* Small devices (mobile phones) */
@media screen and (min-width: 320px) {
  .experience-title {
    text-align: center;
    font-size: 24px;
  }

  .experience-subtitle {
    text-align: center;
    font-size: 24px;
  }

  .experience-text {
    font-size: 14px;
    text-align: center;
  }
}

@media screen and (min-width: 768px) {
  .experience-images-container {
    flex: 1;
  }

  .experience-content {
    flex: 1;
  }

  .experience-title {
    font-size: 48px;
  }

  .experience-subtitle {
    font-size: 48px;
  }

  .experience-text {
    font-size: 18px;
    text-align: left;
  }
}

@media screen and (max-width: 1000px) {
  .experience-images-container {
    flex: 0 0 100%;
  }

  .experience-content {
    flex: 0 0 100%;
  }
}

@media screen and (min-width: 1000px) {
  .experience-title {
    text-align: start;
  }

  .experience-subtitle {
    text-align: start;
  }
}

@media screen and (min-width: 1200px) {
  .experience-images-container {
    flex: 1;
  }

  .experience-content {
    flex: 2;
  }

  .experience-title {
    text-align: start;
    margin-top: 20px;
    font-size: 60px;
  }

  .experience-subtitle {
    text-align: start;
    font-size: 60px;
  }
}