.gradient-container {
  width: 100%;
  height: 300px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.gradient-content h1 {
  margin-left: 20px;
  text-align: left;
  padding: 20px;
}
