.testimonials-container {
  display: flex;
  flex-wrap: wrap;
  /*padding: 10px;*/
  padding-top: 35px;
}

.testimonials-heading {
  display: block;
  flex-basis: 100%;
  text-align: center;
  font-size: 30px;
  margin:0 0;
}

.carousel-container {
  flex-basis: 100%;
  width: 100vw;
}
