.side__header {
  position: fixed;
  width: 50vw;
  top: 0;
  right: 0;
  background-color: transparent;
  z-index: 9999;
  height: 100vh;
}

.nav {
  height: 100%;
  background: white;
  padding-top: 50px;
  position: relative;
}

.nav-link:hover {
  background-color: transparent;
}

.selected-nav {
  width: 100%;
  border-radius: 50px;
  border: 1px solid #ff7420;
}

.nav__toggle,
.nav__close {
  font-size: 1.8rem;
  color: #333;
  cursor: pointer;
}

.nav__list {
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;
}

.nav__close {
  color: black;
  position: absolute;
  top: 1rem;
  right: 1.5rem;
}

.contact-btn {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 460px) {
  .side__header {
    width: 100vw;
  }
}

@media screen and (min-width: 875px) {
  .side__header {
    display: none;
  }
}
