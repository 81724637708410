.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin: 0;
}

.logo-container {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
}

.logo {
  width: 80px;
}

.name{
  margin: 0 30px;
  color: #ff7420;
  text-decoration: none;
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 21px;
  width: 35%;
  display: flex;
  justify-content: flex-end;
}

.nav-container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nav-link {
  margin: 0 30px;
  color: #333;
  text-decoration: none;
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 18px;
}

.selected-nav {
  width: 100%;
  border-radius: 50px;
  border: 1px solid #ff7420;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.primary-button {
  display: flex;
  align-items: center;
}

.nav-close {
  z-index: 9998;
}

.nav-toggle {
  cursor: pointer;
  z-index: 1000;
}

@media screen and (max-width: 667px) {
  .name {
    width: 50%;
    font-size: 20px;
  }
}

@media screen and (max-width: 875px) {
  /* .nav-close {
    display: none;
  }

  .nav-toggle {
    display: none;
  } */

  .nav-container {
    display: none;
  }
}

@media screen and (min-width: 875px) {
  .nav-close {
    display: none;
  }

  .nav-toggle {
    display: none;
  }
}

/* @media screen and (min-width: 875px) {
  .nav-close {
    display: none;
  }

  .nav-toggle {
    display: none;
  }
} */
@media screen and (max-width: 1401px) {
  .nav-link {
    margin: 20px;
  }
}

@media screen and (max-width: 1260px) {
  .nav-link {
    margin: 10px;
  }
}

@media screen and (max-width: 1100px) {
  .nav-link {
    margin: 5px;
  }
}