.quote-section {
  background-image: url(../../../../assets/images/quoteImage.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.quote-section-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0 0 40px 0;
}

.quote-section-title {
  font-size: 35px;
  text-transform: capitalize;
  text-align: center;
  padding: 40px 0 0 0;
}

.quote-section-check {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.quote-section-check img {
  margin-right: 5px;
}

.quote-section-check p {
  text-transform: capitalize;
  font-size: 20px;
}

.checklist-item {
  justify-content: center;
  display: flex;
  gap: 10px;
}

.checklist {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 30px 100px;
}
