/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap'); /* Poppins */

html,
body {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: 'Poppins', sans-serif;
  vertical-align: baseline;
}
