.footer-section {
  padding: 0 10px;
}

@media screen and (max-width: 768px) {
  .about-us {
    flex-basis: 100%;
  }
}
@media screen and (max-width: 240px) {
}

@media screen and (max-width: 667px) {
  .footer-section p{
    width: 280px;
    text-align: justify;
  }
}  

